import React, { useState } from "react";
import axios from "axios";
import OtpInput from "react-otp-input";
import Confetti from "react-confetti";
import { useNavigate } from "react-router-dom";
import { AppConstant } from "../.environments";

export const Registration = () => {
  const [formStep, setFormStep] = useState(1);
  const [otp, setOtp] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [email, setEmail] = useState("");
  const [showConfetti, setShowConfetti] = useState(false);
  const [number, setNumber] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [obgPg, setObgPg] = useState("");
  const [obgResident, setObgResident] = useState("");
  const [interest, setInterest] = useState("");
  const navigate = useNavigate();

  // Transform text to uppercase with underscores
  const transformValue = (value) => value.toUpperCase().replace(/\s+/g, "_");

  const requestOtp = async () => {
    if (email && number && email.trim().length > 0 && number.trim().length > 0) {
      try {
        const { data } = await axios.post(AppConstant.BASE_URL + AppConstant.SIGN_UP, {
          email,
          number,
        });
        setShowError(false);
        setErrorMessage("");
        setShowOtp(true);
      } catch (e) {
        setErrorMessage(`${e.response.data.responseData.errorType} 
          ${e.response.data.responseData.message}`);
        setShowError(true);
      }
    }
  };

  const verifyOTP = async () => {
    if (otp === null || otp === undefined || otp.length < 4) {
      setShowError(true);
      setErrorMessage("Please enter verification code");
      return;
    }
    try {
      const { data } = await axios.post(
        `${AppConstant.BASE_URL}/v1/api/user/verifyotp?email=${email}&otp=${otp}`,
        {}
      );
      setFormStep(2);
    } catch (e) {
      setErrorMessage("Incorrect OTP or OTP is expired");
      setShowError(true);
    }
  };

  const finalSubmit = async () => {
    if (!obgPg || !obgResident || !interest) {
      setErrorMessage("Please select all required dropdown options");
      setShowError(true);
      return;
    }

    const body = {
      firstName,
      lastName,
      email,
      number,
      password,
      ogbPG: transformValue(obgPg),
      ogbResident: transformValue(obgResident),
      interest: interest,
      admin: false,
    };    
    try {
      const { data, status } = await axios.post(AppConstant.BASE_URL + AppConstant.SAVE_USER_DETAIL, body);
      if (status === 200) {
        setShowConfetti(true);
        setTimeout(() => {
          navigate("/");
        }, 5000);
      }
    } catch (e) {
      setErrorMessage("Something went wrong");
      setShowError(true);
    }
  };

  return (
    <div className="w-full h-screen flex justify-center items-center bg-gray-100">
      {showConfetti && <Confetti style={{ width: "100vw", height: "100vh" }} />}
      <div className="flex flex-col w-full max-w-md bg-white shadow-lg rounded-lg px-8 py-10">
        <h1 className="text-4xl font-bold text-center text-gray-800 mb-4">Signup</h1>
        <h2 className="text-lg font-semibold text-center text-gray-600 mb-6">Reproneet</h2>

        {formStep === 1 && (
          <div>
            <input
              className="form-input w-full px-4 py-3 border rounded-md mb-4"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
            />
            <input
              className="form-input w-full px-4 py-3 border rounded-md mb-4"
              type="number"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              placeholder="Mobile Number"
            />
            {showOtp && (
              <div className="mb-5">
                <p className="text-center font-medium mb-2">Enter the OTP sent to your email</p>
                <div className="flex justify-center">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={4}
                  inputStyle={{
                    width: "3rem",
                    height: "3rem",
                    margin: "0.5rem",
                    fontSize: "1.5rem",
                    borderRadius: "0.375rem",
                    border: "1px solid #ced4da",
                  }}
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => <input {...props} />}
                />
                </div>
              </div>
            )}
            {!showOtp ? (
              <button
                className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700"
                onClick={requestOtp}
              >
                Next
              </button>
            ) : (
              <button
                className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700"
                onClick={verifyOTP}
              >
                Verify OTP
              </button>
            )}
          </div>
        )}

        {formStep === 2 && (
          <div>
            <input
              className="form-input w-full px-4 py-3 border rounded-md mb-4"
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="First Name"
            />
            <input
              className="form-input w-full px-4 py-3 border rounded-md mb-4"
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Last Name"
            />
            <input
              className="form-input w-full px-4 py-3 border rounded-md mb-4"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
            <select
              className="form-select w-full px-4 py-3 border rounded-md mb-4"
              value={obgPg}
              onChange={(e) => setObgPg(e.target.value)}
            >
              <option value="">Select OBG PG</option>
              <option value="Completed">Completed</option>
              <option value="Not Completed">Not Completed</option>
              <option value="MBBS Graduate">MBBS Graduate</option>
              <option value="Not Specified">Not Specified</option>
            </select>
            <select
              className="form-select w-full px-4 py-3 border rounded-md mb-4"
              value={obgResident}
              onChange={(e) => setObgResident(e.target.value)}
            >
              <option value="">Select OBG Resident</option>
              <option value="First Year">First Year</option>
              <option value="Second Year">Second Year</option>
              <option value="Third Year">Third Year</option>
              <option value="SR">SR</option>
              <option value="MBBS Graduate">MBBS Graduate</option>
              <option value="Not Specified">Not Specified</option>
            </select>
            <select
              className="form-select w-full px-4 py-3 border rounded-md mb-4"
              value={interest}
              onChange={(e) => setInterest(e.target.value)}
            >
              <option value="">Select Interest</option>
              <option value="OBG_PRACTICE">Course to help in daily OBG practice</option>
              <option value="UNIVERSITY_EXAM">To prepare for University exams - MD/MS/DNB/ DGO</option>
              <option value="SS_EXAM">To prepare for super speciality exams like - NEET SS/FET/INISSET</option>
              <option value="NOT_SPECIFIED">Not Specified</option>
            </select>
            <button
              className="w-full bg-green-600 text-white py-2 rounded-md hover:bg-green-700"
              onClick={finalSubmit}
            >
              Register
            </button>
          </div>
        )}

        {showError && (
          <div className="bg-red-100 text-red-600 text-center py-2 px-4 rounded-md mt-4">
            {errorMessage}
          </div>
        )}
      </div>
    </div>
  );
};
import { useDispatch, useSelector } from "react-redux";
import {
  currentVideo,
  nowPlaying,
  setClearChapter,
} from "../redux/AppStateReducer";
import ReactPlayer from "react-player";
import { Link, useNavigate } from "react-router-dom";
import { Toolbar } from "../toolbar/toolbar";
import { AccordionSummary, Box, Modal, Typography } from "@mui/material";
import {
  setClearCourse,
  setResources,
  updateCourse,
} from "../redux/courseDetailSlice";
import Watermark from "@uiw/react-watermark";
import { useEffect, useState } from "react";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TestSeriesModal from "../test-series/TestSeriesModal";
import { makeNetworkCallGetTestResponseByUserIdAndChapterId } from "../redux/action/TestSeriesAction";
import { RequestType } from "../network/RequestType";
import { ActionType } from "../redux/action/ActionType";
import { replaceCloudFrontBaseUrl } from "../Utils/AppUtils";
import { Constant } from "../constant/constant";

export const LearningScreen = () => {
  const dispatch = useDispatch();
  const {
    userSlice: { userInfo },
    courseDetail: { enrolledCourseDetail },
    appState: { nowPlaying, currentChapter },
  } = useSelector((state) => state);

  const [showDescription, setShowDescription] = useState(false);

  useEffect(() => {
    if (
      localStorage.getItem("currentChapter") !== undefined &&
      localStorage.getItem("currentChapter") !== null
    ) {
      dispatch(
        updateCourse(JSON.parse(localStorage.getItem("currentChapter")))
      );
    }

    return () => {
      dispatch(setClearCourse());
      dispatch(setClearChapter());
    };
  }, []);

  return userInfo.userId != null ? (
    <div className="w-full h-screen bg-gray-50 overflow-x-hidden">
      <Toolbar />

      <div>
        <div className="flex max-md:flex-col max-md:h-screen  ">
          <div className="mx-2 my-2 w-[60%] h-screen max-md:w-96 max-md:h-auto self-center lg:px-20 ">
            <VideoPlayer />

            <div className="text-lg text-gray-700 mb-20font-semibold max-md:text-base max-md:w-full  max-md:px-4 max-md:mb-2 max-md:mt-2  md:mt-5  md:mb-2">
              {nowPlaying?.name}- {nowPlaying?.briefDescription}
            </div>
            <div className="">
              <p className="flex justify-between text-[#454545] text-base font-medium max-md:text-sm max-md:px-4 py-2">
                {enrolledCourseDetail[0]?.title}
                <p
                  className="text-red-700 "
                  onClick={() => setShowDescription(!showDescription)}
                >
                  {showDescription ? "Show Less" : "Show More"}
                </p>
              </p>
            </div>
            {showDescription && (
              <div className="hover:ease-in duration-200  overflow-hidden ">
                <p
                  className={`hover:ease-in duration-200   max-md:text-xs text-[#454545] max-md:px-4  overflow-hidden ${
                    showDescription ? "max-h-96" : "max-h-0 "
                  }`}
                >
                  {enrolledCourseDetail[0]?.description}
                </p>
              </div>
            )}
          </div>
          <Sidebar />
        </div>
      </div>
    </div>
  ) : (
    <div className="bg-white">
      <div
        onClick={() => {
          //   navigatoe("/login");
        }}
        className="w-full h-screen  flex flex-col self-center items-center text-lg justify-center"
      >
        <img
          src={require("../assets/images/undraw_session.png")}
          alt=""
          className="h-[150px] md:h-[250px] mb-10"
          srcset=""
        />
        <Link className="mx-0 px-0" to="/login">
          Ahh! Your session is Expired,{" "}
          <span className="text-blue-500 font-bold">Please login</span>
        </Link>
      </div>
    </div>
  );
};

const VideoPlayer = () => {
  const {
    courseDetail: { enrolledCourseDetail },
    appState: { nowPlaying, currentChapter },
  } = useSelector((state) => state);

  return (
    <div className=" flex justify-center max-sm:mx-4 z-0">
      {(currentChapter.url === "" || currentChapter.url === undefined) && (
        <div className="w-auto md:w-[600px] md:h-[auto] flex content-center justify-center self-center">
          <img
            src={enrolledCourseDetail?.[0]?.thumbnail}
            alt="thumbnail"
            className="self-center w-[100vw] border-2 border-[neutral-500]"
          />
        </div>
      )}
      <Watermark
        content={["Reproneet Learning ", "Dr. Monalisa Singh"]}
        style={{
          background: "#fff",
          justifyContent: "center",
          alignSelf: "center",
          alignItems: "center",
          width: "400px",
        }}
      >
        {console.log("current video url :: ", replaceCloudFrontBaseUrl(currentChapter?.url, Constant.STREAMING_BASE_URL))
        }
        {currentChapter.url !== "" && currentChapter.url !== undefined && (
          <ReactPlayer
            stopOnUnmount={true}
            thumbnail={currentChapter.image}
            width="100%"
            height="auto"
            onBuffer={() => {}}
            playing
            config={{ file: { attributes: { controlsList: "nodownload" } } }}
            controls={true}
            url={currentChapter.url}
            //url={replaceCloudFrontBaseUrl(currentChapter?.url, Constant.STREAMING_BASE_URL)}
          />
        )}
      </Watermark>
    </div>
  );
};

const Sidebar = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sectionData, setSectionData] = useState({});
  const [chapterData, setChapterData] = useState({});
  const testSeriesState = useSelector((state) => state.testSeriesReducer);

  const {
    courseDetail: { enrolledCourseDetail },
    userSlice: { userInfo },
  } = useSelector((state) => state);

  useEffect(() => {
    return () => {
      dispatch({
        type: ActionType.RESET_TEST_RESPONSE_BY_USER_ID_AND_CHAPTER_ID,
        payload: [],
      });
    };
  }, [])

  const playCurrentLecture = (chapter) => {
    dispatch(nowPlaying(chapter));
  };
  const playVideo = (video) => {
    dispatch(currentVideo(video));
  };

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

useEffect(() => {
  if(testSeriesState?.getTestResponseByUserIdAndChapterId?.statusCode === 200){
    if(testSeriesState?.getTestResponseByUserIdAndChapterId?.responseData?.length > 0){
      alert("The test has already been submitted");
      dispatch({
        type: ActionType.RESET_TEST_RESPONSE_BY_USER_ID_AND_CHAPTER_ID,
        payload: [],
      });
    }else{
      handleOpen();
    }
  }
}, [testSeriesState?.getTestResponseByUserIdAndChapterId?.statusCode])

  const handleButtonClick = () => {
    navigate("/test-series", {
      state: {
        chapterId: chapterData?.chapterId,
        sectionId: sectionData?.sectionId,
        courseId: enrolledCourseDetail?.[0]?.courseId,
        userId: userInfo?.userId,
        sectionName: sectionData?.sectionName,
      },
    });
  };

  const handleViewQuestionNavigation = (section, chapter) => {
    if (chapter?.name?.toLowerCase()?.includes("grand test")) {
      setSectionData(section);
      setChapterData(chapter);
      const endPoint = `courseId=${enrolledCourseDetail?.[0]?.courseId}&sectionId=${section?.sectionId}&chapterId=${chapter?.chapterId}&userId=${userInfo?.userId}`;
      dispatch(
        makeNetworkCallGetTestResponseByUserIdAndChapterId(
          RequestType.GET,
          endPoint,
          {},
          {}
        )
      );
    } else {
      navigate("/previous-test-record", {
        state: {
          chapterId: chapter?.chapterId,
          sectionId: section?.sectionId,
          courseId: enrolledCourseDetail?.[0]?.courseId,
          userId: userInfo?.userId,
          sectionName: section?.sectionName,
        },
      });
    }

    // navigate("/previous-test-record", {
    //   state: {
    //     chapterId: chapter?.chapterId,
    //     sectionId: section?.sectionId,
    //     courseId: enrolledCourseDetail?.[0]?.courseId,
    //     userId: userInfo?.userId,
    //     sectionName: section?.sectionName,
    //   },
    // });
  };

  return (
    <div className="lg:w-[35%] h-[90vh] mt-2 overflow-y-scroll  overflow-x-hidden  mr-1 px-1 py-1 max-md:w-full max-md:mt-0 flex justify-center md:w-[350px]">
      {/* <h1>Course Content</h1> */}

      <div>
        {enrolledCourseDetail[0]?.sectionResponse.map((section) => {
          return (
            <Accordion transition transitionTimeout={250} className="mt-2">
              <AccordionItem
                allowMultiple={false}
                header={
                  <>
                    <p className=" py-4 px-6  w-[23rem]  lg:w-[20rem] xl:w-[28rem]   md:w-[300px] self-start font-semibold text-gray-500 text-xs border-indigo-700 border-b-2  md:border-b-4   lg:text-base text-start rounded-lg  md:text-sm ">
                      {"Section " + section.sectionName}
                    </p>
                  </>
                }
              >
                <Accordion className="w-full ">
                  {section.chapterResponse?.map((chapter) => {
                    return (
                      <AccordionItem
                        expandIcon={<ExpandMoreIcon />}
                        allowMultiple={false}
                        className="w-full "
                        header={
                          <>
                            <div className="w-[23rem] xl:w-[28rem] lg:w-[20rem] md:w-[300px] self-center flex  justify-between  py-4 px-4 border-2 border-neutral-100 mt-3 bg-neutral-100 rounded-lg ">
                              <p className=" font-medium  text-xs lg:text-base max-md:mt-2 text-start overflow-hidden md:text-xs ">
                                {"Chapter    "}
                                {chapter.name}{" "}
                              </p>
                              <div className="flex flex-col items-end justify-end w-[8rem]">
                                <p
                                  className="text-blue-700 text-[10px] lg:text-sm font-semibold md:text-[10px] "
                                  onClick={() =>
                                    handleViewQuestionNavigation(
                                      section,
                                      chapter
                                    )
                                  }
                                >
                                  View Questions
                                </p>
                                <p
                                  onClick={() => {
                                    dispatch(
                                      setResources(chapter?.fileResponsePdf)
                                    );
                                  }}
                                  className="font-bold text-[10px] lg:text-sm text-purple-900 md:text-[10px]"
                                >
                                  View Notes
                                </p>
                              </div>
                            </div>
                          </>
                        }
                      >
                        <div
                          onClick={() => playCurrentLecture(chapter)}
                          className="videoItem px-1 py-1  rounded-bl-lg rounded-br-lg bg-neutral-50  max-md:py-4 max-md:px-3 w-[23rem] xl:w-[28rem] md:w-[300px] lg:w-[20rem] "
                        >
                          {/* TODO */}
                          <div className="">
                            {chapter.fileResponseVideo?.map((video, index) => {
                              return (
                                <div className="px-2 mx-1 py-4 mt-4 flex self-center items-center content-center border-b-4 border-indigo-400 rounded-lg text-xs max-md:px-2 max-md:py-4 ">
                                  <img
                                    width={20}
                                    style={{ marginRight: 10 }}
                                    src={require("../assets/images/play-btn.png")}
                                    alt="play icon"
                                    className="max-md:w-4"
                                  />
                                  <div className="flex justify-between ">
                                    <div
                                      onClick={() => playVideo(video)}
                                      className="lg:text-sm"
                                    >
                                      {"  " +
                                        video?.fileName?.replace(".mp4", "")}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </AccordionItem>
                    );
                  })}
                </Accordion>
              </AccordionItem>
            </Accordion>
          );
        })}
      </div>
      <ShowResources />
      <TestSeriesModal
        open={isModalOpen}
        handleClose={handleClose}
        title="Ready to start test"
        description="Once you confirm your test will start."
        buttonCallback={handleButtonClick}
      />
    </div>
  );
};

const ShowResources = () => {
  const {
    userSlice: { userInfo },
    courseDetail,
    appState: { nowPlaying, currentChapter },
  } = useSelector((state) => {
    return state;
  });
  const navigate = useNavigate();

  const dispatch = useDispatch();
  return (
    <Modal
      className="flex items-center justify-center"
      open={courseDetail?.resources?.length > 0}
      onClose={() => dispatch(setResources([]))}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="flex flex-col w-1/3 bg-white px-6 py-4 rounded-lg card border max-md:w-96 overflow-y-auto">
        <p className="font-semibold text-gray-700">Lecture Notes</p>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: 500,
          }}
        >
          {courseDetail?.resources?.map((pdf, index) => {
            return (
              <div
                onClick={() => {
                  const index = pdf?.url?.lastIndexOf("/");
                  // window.open(
                  //   `https://d3an0lws29lp3a.cloudfront.net/data/chapter_file/files/${pdf?.url?.substring(
                  //     index + 1
                  //   )}`,
                  //   "_blank"
                  // );
                  navigate(
                    `/content-viewer/${pdf?.url?.substring(index + 1)}/web`
                  );
                  // window.open(
                  //   `https://www.reproneetlearning.com/content-viewer/${pdf?.url?.substring(
                  //     index + 1
                  //   )}/web`,
                  //   "_blank"
                  // );
                }}
                style={{
                  cursor: "pointer",
                  width: "100%",
                  height: "auto",
                  justifyContent: "center",
                  alignContent: "center",
                  borderRadius: 10,
                  fontFamily: "Lato",
                  fontWeight: 700,
                  marginTop: 20,
                  color: "#454545",
                  textTransform: "capitalize",
                  background: "#f8f8f8",
                }}
              >
                <Typography id="modal-modal-description" sx={{ p: 2 }}>
                  {index + 1}
                  {").  "}
                  {pdf?.fileName?.replace(".pdf", "")}
                </Typography>
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

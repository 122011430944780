const Constant = {
  QUESTION_ATTEMPTED: "QUESTION_ATTEMPTED",
  QUESTION_UNATTEMPTED: "QUESTION_UNATTEMPTED",
  SEVERITY_SUCCESS: "success",
  SEVERITY_ERROR: "error",

  STREAMING_BASE_URL: "https://pub-05c450bead744937814917f74c0680a0.r2.dev"
};

export { Constant };
